import { Link, useSearchParams } from '@remix-run/react';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';
import * as React from 'react';
import { useIsMounted, useWindowSize } from 'usehooks-ts';
import { cn } from '~/utils/cn';
import { buttonVariants } from './Button';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
  ),
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(
  ({ className, ...props }, ref) => <li ref={ref} className={cn(className)} {...props} />,
);
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & React.ComponentProps<typeof Link>;

const PaginationLink = ({ className, isActive, ...props }: PaginationLinkProps) => (
  <Link
    prefetch="intent"
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({ size: 'icon', variant: 'link', class: 'no-underline' }),
      {
        'relative before:absolute before:bottom-0 before:block before:size-2 before:rounded-full before:bg-foreground before:content-[""]':
          isActive,
      },
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    className={cn('gap-1 pl-2.5', className)}
    {...props}
  >
    <ChevronLeft className="size-4" />
    <span>Previous</span>
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink aria-label="Go to next page" className={cn('gap-1 pr-2.5', className)} {...props}>
    <span>Next</span>
    <ChevronRight className="size-4" />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="size-4" />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

interface PaginationItemsProps {
  count: number;
  perPage: number;
  displayedPages?: number;
}

const DISPLAYED_PAGES = 5;

function PaginationItems({
  count,
  displayedPages = DISPLAYED_PAGES,
  perPage: limit,
}: PaginationItemsProps) {
  const { width } = useWindowSize();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();
  const page = Number.parseInt(searchParams.get('page') || '0', 10) + 1;

  const totalPages = Math.ceil(count / limit);

  const DISPLAYED_PAGES = displayedPages;
  const pagesArray = Array.from({ length: totalPages }, (_, i) => i);
  const index = pagesArray.indexOf(page - 1);
  const endPage = index + DISPLAYED_PAGES <= totalPages ? index + DISPLAYED_PAGES : totalPages;
  const startPage = index - DISPLAYED_PAGES >= 0 ? index - DISPLAYED_PAGES : 0;
  let endOffset = DISPLAYED_PAGES - (page - startPage);
  if (endOffset < 0) endOffset = 0;

  const startOffset = DISPLAYED_PAGES - (endPage - page);

  const pages = pagesArray.slice(
    startPage - startOffset < 0 ? 0 : startPage - startOffset,
    endPage + endOffset,
  );

  if (totalPages > 1 && width < 640 && isMounted()) {
    const prev = new URLSearchParams(searchParams);
    const next = new URLSearchParams(searchParams);

    const prevIdx = page - 2;
    const nextIdx = page;
    prev.set('page', '' + prevIdx);
    next.set('page', '' + nextIdx);

    return (
      <>
        {prevIdx} . {page}
        {prevIdx < 0 ? null : (
          <PaginationItem>
            <PaginationLink to={'?' + prev.toString()} className="size-auto aspect-auto px-6">
              Précédent
            </PaginationLink>
          </PaginationItem>
        )}
        {nextIdx >= totalPages ? null : (
          <PaginationItem>
            <PaginationLink to={'?' + next.toString()} className="size-auto aspect-auto px-6">
              Suivant
            </PaginationLink>
          </PaginationItem>
        )}
      </>
    );
  }

  return totalPages > 1 ? (
    <>
      {pages.map((i) => {
        const p = new URLSearchParams(searchParams);
        p.set('page', '' + i);

        return (
          <PaginationItem key={i}>
            <PaginationLink to={'?' + p.toString()} isActive={i === page - 1}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      })}
    </>
  ) : null;
}

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationItems,
};
